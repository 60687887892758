<script lang="ts" setup>
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import pjson from '@@/package.json';

// Plugins & Composables
const { t } = useI18n();
const router = useRouter();
const { getSSOFromURL } = useSSO();

// Stores
const firebase = firebaseStore();

// Crip
const crisp = useScriptCrisp({
  id: '6eb8f29f-9750-4c5d-980e-a54b63c1c888',
});
crisp.do('chat:close');
crisp.do('chat:show');

// Data
const loading = ref(false);
const version = pjson.version;

// Form
const schema = yup.object().shape({
  email: yup.string().email(t('auth.form.field_email')).required(t('auth.form.field_required')),
  password: yup.string().required(t('auth.form.field_required')),
});
const { handleSubmit } = useForm<{ email: string; password: string }>({
  validationSchema: schema,
});

// Methods
const submit = handleSubmit(async (values) => {
  loading.value = true;
  await firebase.signIn(values);
  loading.value = false;
});
const submitSS0 = async () => {
  loading.value = true;
  await firebase.signInWithSSO(getSSOFromURL());
  loading.value = false;
  router.push('/');
};
</script>

<template>
  <ui-card class="w-[450px]">
    <div class="w-full flex items-center justify-center flex-col py-2">
      <img class="h-[100px]" src="assets/full-logo.png" />
    </div>

    <ui-button v-if="getSSOFromURL()" class="w-full mt-6" :loading="loading" @click="submitSS0()">
      {{ t('auth.login') }}
    </ui-button>

    <form v-else @submit.prevent="submit()">
      <ui-form-input-text name="email" :label="t('auth.form.email')" placeholder="jean.dupont@mail.com" />
      <ui-form-input-text name="password" type="password" :label="t('auth.form.password')" placeholder="***********" />

      <div class="flex justify-end w-full">
        <router-link to="/auth/reset-password" class="text-end text-sm text-green-700">
          {{ $t('global.forgot_password') }}
        </router-link>
      </div>

      <ui-button class="w-full mt-6" :loading="loading" @click="submit">
        {{ t('auth.login') }}
      </ui-button>
    </form>

    <div class="flex w-full justify-center mt-4 text-primary-500">
      {{ `v${version}` }}
    </div>
  </ui-card>
</template>
